import React from 'react';
import heroimg from './img/hero3.png';
import About from './img/about.jpg'
import { Card} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import card1 from './img/1.png';
import card2 from './img/2.png';
import card3 from './img/3.png';
import card4 from './img/4.png';
import card5 from './img/5.jpg';
import card6 from './img/7.png';
import faq from './img/event3.png';
import chris from './img/Christian Dabone .jpg';
import helen from './img/Helen.png';
import timi from './img/Timileyin Oguntolu .jpg';
import Footer from './Footer';
import Navbar from './Navbar';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Homepage = () => {   

        AOS.init();
    
      

    return ( 

        <div>

            <Navbar/>

            <section className="hero-section px-lg-0 py-lg-0 px-2 py-4">

                <div className='container mt-5'>
                    <div className='row d-flex align-items-center vh-100'>

                        <div className='col-lg-6 px-lg-0'>
                            <h1 className='fw-bold display-5'>Empowering you with entrepreneurial skills to become<span> financially independent</span> </h1>
                            <p className="mt-3 col-lg-11">With Adiz academy you will learn how to develop and generate income from entrepreneurial skills.</p>

                            <div className='col-lg-6 d-block d-lg-none mb-5'>

                                <img src={ heroimg } className='img-fluid' alt="" />
                               
                            </div>

                            <div className='mt-lg-5 buttons'>
                               <a href='#courses'><button className='btn btn-light px-lg-5 py-lg-3 px-4 py-2 me-4 fw-bold'>View Courses</button></a> 
                                <a href='#about-us'><button className='btn btn-outline-light px-lg-5 py-lg-3 px-4 py-2 fw-bold'>Read More</button></a> 
                            </div>

                        </div>

                         <div className='col-lg-6 d-none d-lg-block' data-aos="flip-left" data-aos-duration="2500">
                            <img src={ heroimg } className='img-fluid' alt="" />
                            <small className='justify-content-center m-auto px-4 text-center'> <i className='fa fa-check-circle text-white'></i> Over 100+ young Africans have started their skill game journey with us</small>
                            
                        </div>

                    </div>
                </div>
                    
            </section>




            <section className='courses mt-lg-5 mt-3 pt-5 m-2' id="courses" data-aos="fade-down" data-aos-duration="1000">
                <div className='container mb-5 pb-5'>

                    <div className='justify-content-center text-center m-auto pt-5 mb-3 pb-3'>
                        <h1 className='display-4 fw-bold'> Choose <span>the best course</span> for you </h1>
                        <small>Selectively hand-picked courses to help you scale</small>
                    </div>
                    

                    <div className='row' data-aos="fade-right" data-aos-duration="800">

                        <div className='col-12 col-lg-4 mb-lg-0 mb-5'>
                            <Card className='h-100 px-3 py-3'>
                                <Card.Img src={ card4 }/>
                                <Card.Body className='mt-3'>
                                    <Card.Title className='mb-3 fw-bold'>Graphic Design Essentials (GDE) </Card.Title>
                                    <Card.Text className='mb-5'>
                                    Discover how to create a wide variety of designs on your smartphone and earn income by providing design services to others with our comprehensive design course.
                                    </Card.Text>
                                    <div className="d-flex justify-content-center text-center m-auto">                                
                                    <button className="enroll-button w-100"><a href="https://selar.co/rqvm" target="_blank" rel="noopener noreferrer">Take this course</a></button>
                                    </div>                               
                                </Card.Body>
                            </Card>
                        </div>


                        <div className='col-12 col-lg-4 mb-lg-0 mb-5'>
                            <Card className='h-100 px-3 py-3'>
                                <Card.Img src={ card5 }/>
                                <Card.Body className='mt-3'>
                                    <Card.Title className='mb-3 fw-bold'>Copywriting For Beginners</Card.Title>
                                    <Card.Text className='mb-5'>
                                    Learn how to write persuasive copies that convert into sales with our beginner's copywriting course. Suitable for business owners and aspiring copywriters.
                                    </Card.Text>
                                    <div className="d-flex justify-content-center text-center m-auto">                                
                                    <button className="enroll-button w-100"><a href="https://selar.co/2oxy" target="_blank" rel="noopener noreferrer">Take this course</a></button>
                                    </div> 
                                </Card.Body>
                            </Card>
                        </div>


                        <div className='col-12 col-lg-4'>
                            <Card className='h-100 px-3 py-3'>
                                <Card.Img src={ card2 }/>
                                <Card.Body className='mt-3'>
                                    <Card.Title className='mb-3 fw-bold'>Get More Clients (GMC) </Card.Title>
                                    <Card.Text className='mb-5'>
                                    This course would teach you proven strategies you can employ to get clients that will pay you well for what you do. This course is for online entrepreneurs who sell service. 
                                    </Card.Text>
                                    <div className="d-flex justify-content-center text-center m-auto">                                
                                    <button className="enroll-button w-100"><a href="https://selar.co/zfaj" target="_blank" rel="noopener noreferrer">Take this course</a></button>
                                    </div> 
                                </Card.Body>
                            </Card>
                        </div>
                    
                    </div>



                    <div className='row mt-5' data-aos="fade-right" data-aos-duration="2500">

                        <div className='col-12 col-lg-4 mb-lg-0 mb-5'>
                            <Card className='h-100 px-3 py-3'>
                                <Card.Img src={ card3 }/>
                                <Card.Body className='mt-3'>
                                    <Card.Title className='mb-3 fw-bold'>Social Media Design Dynamics</Card.Title>
                                    <Card.Text className='mb-5'>
                                    From the conception of the idea to the final development stage, i am the specialist that you need
                                    </Card.Text>
                                    <div className="d-flex justify-content-center text-center m-auto">                                
                                    <button className="enroll-button w-100"><a href="https://selar.co/iu9f" target="_blank" rel="noopener noreferrer">Take this course</a></button>
                                    </div> 
                                
                                </Card.Body>
                            </Card>
                        </div>


                        
                        <div className='col-12 col-lg-4  mb-lg-0 mb-5'>
                            <Card className='h-100 px-3 py-3'>
                                <Card.Img src={ card1 }/>
                                <Card.Body className='mt-3'>
                                    <Card.Title className='mb-3 fw-bold'>Beginners Design Course</Card.Title>
                                    <Card.Text className='mb-5'>
                                    Learn how to create simple yet functional social media design on your smartphone even if you have no prior knowledge about Graphic Design.
                                    </Card.Text>
                                    <div className="d-flex justify-content-center text-center m-auto">                                
                                    <button className="enroll-button w-100"><a href="https://selar.co/a6y6" target="_blank" rel="noopener noreferrer">Take this course</a></button>
                                    </div> 
                                    
                                </Card.Body>
                            </Card>
                        </div>


                        <div className='col-12 col-lg-4'>
                            <Card className='h-100 px-3 py-3'>
                                <Card.Img src={ card6 }/>
                                <Card.Body className='mt-3'>
                                    <Card.Title className='mb-3 fw-bold'>Website Design Simplified</Card.Title>
                                    <Card.Text className='mb-5'>
                                    Learn how to create unique, fast and responsive websites with simple step by step process writing codes, deploying on a web 
                                    hosting server and much more
                                    </Card.Text>
                                    <div className="d-flex justify-content-center text-center m-auto">                                
                                    <button className="enroll-button w-100"><a href="https://grtnxhor.notion.site/Website-Training-Curriculum-4a2fcc0437674aa7a2df447445562b7c" target="_blank" rel="noopener noreferrer">Take this course</a></button>
                                    </div> 
                                
                                </Card.Body>
                            </Card>
                        </div>


                    
                    </div>

                </div>
            </section>



            <section className="about mt-5 pt-3 pb-5" id="about-us" data-aos="flip-left" data-aos-duration="500">

                <div className="container">


                    <div className="justify-content-center text-center m-auto pt-5 mt-5 mb-3 pb-3 ">
                        <h1 className='display-4 fw-bold'>Why <span>Adiz Academy?</span></h1>
                        <small>Become independent by taking control of your life</small>
                    </div>
                

                    <div className='row mt-5 pt-3' data-aos="flip-left" data-aos-duration="800">

                        <div className="col-lg-6 col-12 px-4">
                            
                            <p className='about-text mb-5'>
                            After investing years of your time, money, and effort into your education, you may come to realize that 
                            a significant portion of your life may have been spent acquiring knowledge that may not be practical or 
                            relevant to your career goals. With the rise of skill-based hiring, artificial intelligence, and remote
                                work, traditional graduate job offers are becoming less prevalent.
                            </p>

                            <h5> <i className='fa-solid fa-check-circle text-dark me-2 abt-icon'></i>Become more valuable</h5>
                            <h5> <i className='fa-solid fa-check-circle text-dark me-2 abt-icon'></i>Create a job for yourself</h5>
                            <h5> <i className='fa-solid fa-check-circle text-dark me-2 abt-icon'></i>Start your own business </h5>
                            <h5> <i className='fa-solid fa-check-circle text-dark me-2 abt-icon'></i>Make money creating products.</h5>

                            <p className='mt-5 mb-5 pb-5'>Adiz Academy can help you bridge the gap between where you are now and where you want to be 
                            by providing you with real-time entrepreneurial skills. These skills can help you to pursue your career aspirations with 
                            confidence and increase your chances of success in the rapidly changing job market.
                            </p>

                        </div>

                        <div className="col-lg-6 col-12 mb-5 d-none d-lg-block d-md-block">
                            <img src={About} alt="about" className='w-100 '></img>
                        </div>

                    </div>



                    <div className='row benefit'>
                
                        <div className="col-lg-3 col-6" data-aos="flip-up" data-aos-duration="500">

                            <Card className='px-4 py-5 text-center m-auto'>
                                <Card.Title className='fw-bold display-6'><i className='fa fa-certificate'></i></Card.Title>
                                <Card.Body className='d-flex align-items-center m-auto'>
                                    <Card.Title className='mb-3 fw-bold col-lg-10 m-auto'>Certificates on course completion</Card.Title>
                                </Card.Body>
                            </Card>
                        </div>


                        <div className="col-lg-3 col-6" data-aos="flip-up" data-aos-duration="800">

                            <Card className='px-4 py-5 text-center m-auto'>
                                <Card.Title className='fw-bold display-6'><i className='fa fa-clock'></i></Card.Title>
                                <Card.Body className='d-flex align-items-center m-auto'>
                                    <Card.Title className='mb-3 fw-bold col-lg-10 m-auto'>Lifetime access to courses</Card.Title>
                                </Card.Body>
                            </Card>
                    
                        </div>



                        <div className="col-lg-3 col-6" data-aos="flip-up" data-aos-duration="1000">

                            <Card className='px-4 py-5 text-center m-auto'>
                                <Card.Title className='fw-bold display-6'><i className='fa fa-users'></i></Card.Title>
                                <Card.Body className='d-flex align-items-center m-auto'>
                                    <Card.Title className='mb-3 fw-bold col-lg-10 m-auto'>Experienced & trained instructors</Card.Title>
                                </Card.Body>
                            </Card>

                        </div>



                        <div className="col-lg-3 col-6" data-aos="flip-up" data-aos-duration="1500">
                            
                            <Card className='px-4 py-5 text-center m-auto'>
                                <Card.Title className='fw-bold display-6'><i className='fa fa-briefcase'></i></Card.Title>
                                <Card.Body className='d-flex align-items-center m-auto'>
                                    <Card.Title className='mb-3 fw-bold col-lg-11 m-auto'>Access to jobs and opportunities</Card.Title>
                                </Card.Body>
                            </Card>

                        </div>

                       
                    </div>

                </div>

            </section>


            <section className="faq pt-5 mt-5 mb-5 pb-5 m-lg-0 m-2" id="FAQs" data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-duration="2000">
                
                <div className="container">
                   
                   <div className='row'>

                        <div className='col-lg-5 me-4'>

                            <div className="m-auto pb-3">
                                <h1 className='fw-bold col-10 m-lg-0 m-2'>Frequently <span>Asked Questions 🤔</span></h1>
                            </div>

                            <div className="accordion mt-1" id="accordionExample">

                                <div className="accordion-item show">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            What is Adiz Academy?
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body active">
                                            Adiz Academy is an online learning platform that provides courses on entrepreneurship and skill development. Our courses are designed to empower individuals with the skills needed to become financially independent.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            How long are the courses?
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            The duration of our courses varies depending on the topic and level of the course. Most of our courses are self-paced, which means you can complete them at your own speed. However, some courses have a set duration and may have weekly assignments and assessments.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            How do I enroll in a course?
                                        </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            To enroll in a course, simply go to the course page and click on the "Enroll" button. You will be prompted to create an account if you haven't already. Once you've created an account and completed the payment process, you will have access to the course.
                                        </div>
                                    </div>
                                </div>

                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                            Can I get a refund if I'm not satisfied with the course?
                                        </button>
                                    </h2>
                                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            Yes, we offer a 30-day money-back guarantee for all our courses. If you're not satisfied with the course, 
                                            you can request a refund within 30 days of your purchase. Please refer to our refund policy for more information

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>


                        <div className='col-lg-6 mt-lg-0 pt-lg-0 mt-5 pt-5' data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000">

                            <div className="justify-content-center text-center m-auto pb-3 h-100">
                                <img src={ faq } alt="" className='img-fluid img-responsive h-100'/>
                            </div>

                        </div>
                   </div>

                </div>

            </section>



            <section className='testi pt-lg-5 mt-lg-5 pt-2 pb-5 mb-5 m-lg-0 m-2'>
                <div className='container'>
                    <div className='justify-content-center text-center m-auto'>
                        <div className="m-auto pb-3 mt-3 mb-4">
                                <h1 className='display-4 fw-bold'>What others have <span>gained</span></h1>
                                <small>Read all about how how Adiz Academy have many gained their skill and jobs</small>
                         </div>

                         <div className='row'>
                           
                            <div className='col-lg-4 mb-lg-0 mb-4' data-aos="flip-up" data-aos-duration="800">
                                <Card className='px-1 py-5 text-start m-auto'>
                                    <Card.Body className='d-flex align-items-center m-auto'>
                                        <Card.Text>This course is superb. Teaching from the basic of graphic design to becoming a pro. Within few weeks/month into the course and the community I have been creating sweet designs. 🥳🥳

                                                One thing that amaze me is your creativity and your style of teaching. You teach in a way that even a 7year old child would assimilate and learn fast. You pour out your knowledge on us seamlessly. 

                                                This course is top-notch.

                                                I will recommend this course over and over again


                                            </Card.Text>
                                           
                                    </Card.Body>
                                    
                                                <div className='col-12 px-4 mt-3'>
                                                    <img src={ timi } className='img-fluid img-responsive me-3' alt="" />
                                                    <small className='fw-bold'>Timileyin Oguntolu</small>
                                                </div>
                                               
                                </Card>
                            </div>

                            <div className='col-lg-4 mb-lg-0 mb-4' data-aos="flip-up" data-aos-duration="1000">
                                <Card className='px-1 py-5 text-start m-auto'>
                                    <Card.Body className='d-flex align-items-center m-auto'>
                                        <Card.Text>Last month, January A woman in the UK chatted me for social Media management job

                                                Mind you, I've stopped posting about it since last year infact I don't tell people I'm a social media manager anymore

                                                Guess what, her gig is bigger than any social media management job I've ever gotten since I started

                                                I haven't even started with the proper content I planned for the year o

                                                Thank you Adiz Academy.

                                            </Card.Text>
                                           
                                    </Card.Body>
                                    
                                                <div className='col-12 px-4 mt-3'>
                                                    <img src={ helen } className='img-fluid img-responsive me-3' alt="" />
                                                    <small className='fw-bold'>Helen</small>
                                                </div>
                                               
                                </Card>
                            </div>

                            <div className='col-lg-4' data-aos="flip-up" data-aos-duration="1500">
                                <Card className='px-1 py-5 text-start m-auto'>
                                    <Card.Body className='d-flex align-items-center m-auto'>
                                        <Card.Text>I have been going through the course since morning up till now...

                                                I have been designing rubbish since not knowing why are the way the are... ignorance is a bad thing ooo sir.

                                                That course has opened my eyes and mind to creating stunning and attention grabbing designs now.

                                                God bless you once again, thank God I asked for the course I never regretted it at all.

                                                You're just amazing 
                                            </Card.Text>
                                           
                                    </Card.Body>
                                    
                                                <div className='col-12 px-4 mt-3'>
                                                    <img src={ chris } className='img-fluid img-responsive me-3' alt="" />
                                                    <small className='fw-bold'>Christian Dabone</small>
                                                </div>
                                               
                                </Card>
                            </div>

                         </div>
                    </div>
                </div>
            </section>


            <section className='started pt-5 mt-5 mb-5 pb-5 m-lg-0 m-2' data-aos="flip-left" data-aos-duration="1500">
                <div className='container pb-5 mb-5'>

                    <Card className='col-lg-10 justify-content-center text-start m-auto'>
                        <Card.Body>

                            <div className='py-5 px-lg-5 px-3 m-lg-5'>
                                <h1 className="mb-3 display-4 fw-bold">Join Our Community</h1>
                                <p className="mt-4 mb-5 lead">Our Community is where we share more intimate contents with our members. 
                                We share major announcements and helpful Contents to help you grow and learn.⚡</p>

                                <a target='_blank' rel='noreferrer' href="https://api.whatsapp.com/send?phone=2349066647086&text=Hello,%20I%27ll%20love%20to%20join%20the%20Adiz%20Academy%20Community.%20My%20name%20is%20---"><button type="submit" className="btn py-3 btn-light btn-join">Join Us Now</button></a>
                                
                            </div>
                                                
                        </Card.Body>
                         
                    </Card>

                </div>

            </section>

            <Footer/>
        </div>
          

    

    );
}
 
export default Homepage;
