import React , { useState, useEffect }  from "react";
import logo from './img/logo4.png';

function Footer() {


  const [year, setYear] = useState("");

  useEffect(() => {
    const now = new Date();
    const year = now.getFullYear();
    setYear(year);
  }, []);



  return (


        <footer className="footer pt-5 mt-5">
          <div className="container py-5">

                  
                  
            <div className="row m-2">

                <div className="col-lg-6 mt-2">
                  <img src={logo} alt="logo" className="img-fluid img-responsive" width="200" />
                  <p className="mt-3 col-10">Adiz Academy can help you bridge the gap between where you are now and where you want to be by providing you with real-time entrepreneurial skills. 
                  These skills can help you to pursue your career aspirations with confidence and increase your chances of success in the rapidly changing job market.</p>
                </div>

                <div className="col-lg-3 mt-5">
                  <h1>Quick links</h1>
                  <ul className="list-unstyled mt-4">
                    <li><a href="#home" className="footer-link">Home</a></li>
                    <li><a href="#about-us" className="footer-link">About us</a></li>
                    <li><a href="#courses" className="footer-link">Our Courses</a></li>
                    <li> <a target='_blank' rel='noreferrer' href="https://api.whatsapp.com/send?phone=2349066647086&text=Hello,%20I%27ll%20love%20to%20join%20the%20Adiz%20Academy%20Community.%20My%20name%20is%20---" className="footer-link">Join Our Community</a></li>
                  </ul>
                </div>
            

                <div className="col-lg-3 mt-5">
                  <h1>Follow us;</h1>
                  <ul className="list-unstyled mt-4">
                    <li><a target='_blank' rel='noreferrer' href="https://api.whatsapp.com/send?phone=2349066647086&text=Hello,%20I%27ll%20love%20to%20join%20the%20Adiz%20Academy%20Community.%20My%20name%20is%20---" className="footer-link"><i className="fab fa-whatsapp"></i>WhatsApp</a></li>
                    <li><a target='_blank' rel='noreferrer' href="https://www.instagram.com/adizacademy/?hl=en" className="footer-link"><i className="fab fa-instagram"></i>Instagram</a></li>
                    <li><a target='_blank' rel='noreferrer' href="https://twitter.com/adizacademy" className="footer-link"><i className="fab fa-twitter"></i>Twitter</a></li>
                    <li><a target='_blank' rel='noreferrer' href="https://www.youtube.com/@adizacademy" className="footer-link"><i className="fab fa-youtube"></i>YouTube</a></li>
                  </ul>
                </div>

                
            </div>
           
              
            <div className='justify-content-center text-center pt-5 mt-5'>
                <small>&copy; Adiz Academy {year} &nbsp;&nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;&nbsp; Developed by: <a className="text-decoration-none text-white" href="https://hynitr.com" rel="noreferrer" target="_blank">Hynitr</a></small>
            </div>

          </div>
                    
        </footer>


  );
}

export default Footer;
